import { Route, Routes } from "react-router-dom";
import Lobby from "./pages/Lobby";
import Room from "./pages/Room";
import Entry from "./pages/Entry";

function App() {
   return (
      <div>
         <Routes>
            <Route path="/" element={<Lobby />} />
            <Route path="/room/:roomId" element={<Room />} />
            <Route path="/lobby/:roomId" element={<Entry />} />
         </Routes>
      </div>
   );
}

export default App;

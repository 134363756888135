import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const Entry = () => {
    const navigate = useNavigate();
    const [isAppoinmentReady, setIsAppoinmentReady] = useState(false);
    // useEffect(() => {
    //     const appointmentInfo = fetch("").then(res => res.json())
    //     if(appointmentInfo.data.isAppoinmentReady){
    //         navigate(`/room/${appointmentInfo.data.appoinmentId}`);
    //     }
    // }, [navigate])
  return (
    <div>{
        isAppoinmentReady ? <h1>Appoinment is ready</h1> : <h1>Appoinment is not ready</h1>}</div>
  )
}

export default Entry
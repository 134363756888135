import React, { useRef, useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Peer from "simple-peer";
import socket from "../utils/socket";
import micOn from "../assets/mic_on.svg";
import micOff from "../assets/mic_off.svg";
import videoOff from "../assets/video_off.svg";
import videoOn from "../assets/video_on.svg";
import callEnd from "../assets/call_end.svg";
import VideoPlayer from "../components/VideoPlayer";
import getIceServers from "../utils/iceServers";

const Room = () => {
   const { roomId } = useParams();
   const location = useLocation();
   const userId = new URLSearchParams(location.search).get("userId");

   const [callAccepted, setCallAccepted] = useState(false);

   const [audio, setAudio] = useState(true);
   const [video, setVideo] = useState(true);
   const [remoteVideo, setRemoteVideo] = useState(true);
   const [remoteAudio, setRemoteAudio] = useState(true);

   const userVideo = useRef();
   const partnerVideo = useRef();
   const userStream = useRef();
   const peer = useRef();

   const navigate = useNavigate();

   const callUser = async () => {
      console.log("call user fucntion", userStream.current);
      const iceServers = await getIceServers();
      peer.current = new Peer({
         initiator: true,
         trickle: false,
         stream: userStream.current,
         config: {
            iceServers: iceServers,
         },
      });

      peer.current.on("error", (err) => {
         console.log(err);
      });

      peer.current.on("connect", () => {
         console.log("Connected");
         console.log(peer.current);
      });

      peer.current.on("signal", (data) => {
         console.log("on signal caller side");
         socket.emit("offer", { roomId, from: userId, signal: data });
      });

      peer.current.on("stream", (stream) => {
         console.log("on stream caller side");
         if (partnerVideo.current) {
            partnerVideo.current.srcObject = stream;
         }
      });
   };
   const acceptCall = async (signal) => {
      console.log("accept call function", userStream.current);
      setCallAccepted(true);
      const iceServers = await getIceServers();
      peer.current = new Peer({
         initiator: false,
         trickle: false,
         stream: userStream.current,
         config: {
            iceServers: iceServers,
         },
      });

      peer.current.on("error", (err) => {
         console.log(err);
      });

      peer.current.on("connect", () => {
         console.log("Connected");
      });

      peer.current.signal(signal);

      peer.current.on("stream", (stream) => {
         console.log("on stream receiver side");
         if (partnerVideo.current) {
            partnerVideo.current.srcObject = stream;
         }
      });

      peer.current.on("signal", (data) => {
         console.log("on signal receiver side");
         socket.emit("answer", { roomId, from: userId, signal: data });
      });

      socket.emit("call-accepted", { roomId, from: userId });
   };

   const endCall = () => {
      if (peer.current) {
         peer.current.destroy();
      }

      if (userStream.current) {
         userStream.current.getTracks().forEach((track) => track.stop());
      }

      setCallAccepted(false);
      if (partnerVideo.current) {
         partnerVideo.current.srcObject = null;
      }

      socket.emit("end-call", { roomId, from: userId });
      navigate(`/`);
      window.location.reload();
   };

   const toggleAudio = () => {
      if (userStream.current) {
         userStream.current
            .getAudioTracks()
            .forEach((track) => (track.enabled = !track.enabled));
      }
      socket.emit("toggle-audio", { roomId, from: userId, isOn: !audio });
      setAudio(!audio);
   };
   const toggleVideo = () => {
      if (userStream.current) {
         userStream.current
            .getVideoTracks()
            .forEach((track) => (track.enabled = !track.enabled));
      }
      socket.emit("toggle-video", { roomId, from: userId, isOn: !video });
      setVideo(!video);
   };

   const remoteAudioToggled = ({ isOn }) => {
      setRemoteAudio(isOn);
   };

   const remoteVideoToggled = ({ isOn }) => {
      setRemoteVideo(isOn);
   };

   const remoteUSerLeft = () => {
      if (peer.current) {
         peer.current.destroy();
      }
      setCallAccepted(false);
   };

   useEffect(() => {
      navigator.mediaDevices
         .getUserMedia({ video: true, audio: true })
         .then((stream) => {
            userStream.current = stream;
            if (userVideo.current) {
               userVideo.current.srcObject = stream;
            }
         });

      socket.on("ready-to-call", ({ userId: otherUserId }) => {
         console.log("ready-to-call sodket", otherUserId);
         if (otherUserId !== userId) {
            callUser();
         }
      });

      socket.on("offer", ({ signal }) => {
         console.log("offer socket", signal);
         acceptCall(signal);
      });

      socket.on("answer", ({ signal }) => {
         console.log("answer socket caller side", signal);
         peer.current.signal(signal);
      });

      socket.on("call-accepted", ({ userId: otherUserId }) => {
         console.log("call-accepted", otherUserId);
         setCallAccepted(true);
      });

      socket.on("audio-toggled", remoteAudioToggled);
      socket.on("video-toggled", remoteVideoToggled);

      socket.on("call-ended", () => {
         remoteUSerLeft();
      });

      return () => {
         socket.off("offer");
         socket.off("answer");
         socket.off("ready-to-call");
         socket.off("call-accepted");
         socket.off("call-ended");
         socket.off("audio-toggled");
         socket.off("video-toggled");
      };
   }, [roomId, userId]);

   return (
      <div className="w-screen h-screen relative">
         <h1>Room: {roomId}</h1>
         <div className="w-full h-5/6 flex justify-center items-center relative">
            <VideoPlayer
               playsInline
               muted
               ref={userVideo}
               autoPlay
               className={`transition-all duration-300 z-10 ${
                  callAccepted
                     ? "w-1/4 h-1/4 absolute bottom-4 right-2"
                     : "w-full h-full"
               }`}
            />
            {callAccepted && (
               <div className="relative w-full h-auto md:h-full">
                  <VideoPlayer
                     playsInline
                     ref={partnerVideo}
                     autoPlay
                     className="w-full h-full"
                  />
                  <div className="absolute top-2 right-2 flex space-x-2">
                     {!remoteVideo && (
                        <img
                           src={videoOff}
                           alt="video status"
                           className="w-6 h-6"
                        />
                        // <p className="text-red-500">Audio</p>
                     )}
                     {!remoteAudio && (
                        <img
                           src={micOff}
                           alt="mic status"
                           className="w-6 h-6"
                        />
                        // <p className="text-red-500 text-3xl">Audio</p>
                     )}
                  </div>
               </div>
            )}
         </div>
         <div className="w-full h-12 py-8 flex justify-center items-center gap-5">
            <button className="w-10" onClick={toggleAudio}>
               <img src={audio ? micOn : micOff} alt="mic" />
            </button>
            <button className="w-10" onClick={toggleVideo}>
               <img src={video ? videoOn : videoOff} alt="video" />
            </button>
            <button className="w-10" onClick={endCall}>
               <img src={callEnd} alt="call end" />
            </button>
         </div>
      </div>
   );
};

export default Room;

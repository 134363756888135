import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import socket from "../utils/socket";

const Lobby = () => {
   const [email, setEmail] = useState("");
   const [roomId, setRoomId] = useState("");
   const navigate = useNavigate();

   const handleSubmit = (e) => {
      e.preventDefault();
      const userId = email; // Use email as userId for simplicity
      socket.emit("join-room", { roomId, userId });
      navigate(`/room/${roomId}?userId=${userId}`);
   };

   return (
      <div className="w-full h-screen flex flex-col colum justify-center items-center ">
         <h1 className="text-3xl font-bold mb-5">Video Module</h1>
         <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center justify-center gap-5 m-2"
         >
            <div className="flex flex-col gap-[5px] w-80">
               <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="border-2 border-gray-300 p-2 rounded-md"
                  placeholder="Enter Email"
               />
            </div>
            <div className="flex flex-col gap-[5px] w-80">
               <input
                  type="text"
                  value={roomId}
                  onChange={(e) => setRoomId(e.target.value)}
                  required
                  placeholder="Enter Room ID"
                  className="border-2 border-gray-300 p-2 rounded-md"
               />
            </div>
            <button
               className="border-2 border-gray-300 p-2 rounded-md w-80 cursor-pointer hover:bg-gray-300"
               type="submit"
            >
               Join Room
            </button>
         </form>
      </div>
   );
};

export default Lobby;

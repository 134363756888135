import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import * as process from "process";
import { BrowserRouter } from "react-router-dom";

window.global = window;
window.process = process;
window.Buffer = [];

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
   <React.StrictMode>
      <BrowserRouter>
            <App />
      </BrowserRouter>
   </React.StrictMode>
);
